<template>
  <form @submit.prevent="save" class="w-m-content">
    <div class="w-m-header">
      <p class="font-14 no-margin no-select" style="color: #293e52">Registrar pagamento na etapa <strong>{{
          etapa.nome
        }}</strong>
      </p>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size1">
      <div class="font-11">
        {{ pagamento.pessoa }}
        <produtividade-pessoa-select :etapa="etapa" v-model="pagamento.pessoa"/>


        <!--        <erp-s-field class="m-t" label="Valor">
                  <erp-input v-model="pagamento.valor" v-money="money"/>
                </erp-s-field>

                <erp-s-field class="m-t e-input-modern size1" label="Descricao">
                  <textarea class="full-width app-input input-textarea" rows="5" v-model="pagamento.observacoes">
                  </textarea>
                </erp-s-field>-->

      </div>
    </div>
    <div class="w-m-footer text-right footer-modern">
      <u-btn @click="$uloc.window.close($root.wid)" label="Cancelar" no-caps
             :color="'white'"
             text-color="grey-8"
             class="b-radius-3px btn-white m-r-sm"/>
      <u-btn :loading="loading" type="submit" label="Registrar pagamento" no-caps
             :color="'primary'"
             class="b-radius-3px"/>
    </div>
  </form>
</template>

<script>
import {ErpInput, ErpSField} from 'uloc-vue-plugin-erp'
import PersonMixin from "@/components/pessoa/components/include/PersonMixin"
// import {UCheckbox} from "uloc-vue"
// import {registraPagamentoEtapa} from "@/domain/processos/services/produtividade"
import ProdutividadePessoaSelect from "@/components/processos/components/produtividade/PessoaSelect"
import {VMoney} from "v-money"
import {convertRealToMoney, REAL_BRL} from "@/utils/money"
import windowConta from "@/components/financeiro/windows/conta"

export default {
  name: 'ProdutividadeEtapaPagamentoWindow',
  directives: {money: VMoney},
  mixins: [PersonMixin],
  components: {
    ProdutividadePessoaSelect,
    // ErpInput,
    // ErpSField
  },
  props: ['options', 'router', 'etapa', 'processo'],
  data() {
    return {
      money: REAL_BRL,
      loading: false,
      success: false,
      pagamento: {
        id: null,
        pessoa: null,
        valor: null
      }
    }
  },
  mounted() {
  },
  computed: {},
  methods: {
    windowConta: windowConta,
    save() {
      if (!this.pagamento.pessoa) {
        this.$uloc.dialog({
          title: 'Pessoa obrigatória.',
          message: 'Você não selecionou uma pessoa para registrar o pagamento.'
        })
        return
      }
      // this.loading = true
      const data = JSON.parse(JSON.stringify(this.pagamento))
      data.valor = convertRealToMoney(data.valor)
      this.windowConta(null, 'd', 'produtividade-etapa', this.etapa.id, this.pagamento.pessoa, [
        {
          entity: 'processo',
          entityId: this.etapa.produtividade.processo.id
        }
      ]) // @TODO: Pessoa?
      /*registraPagamentoEtapa(this.etapa, data)
          .then(response => {
            this.$uloc.window.emit(this.$root.wid, 'updated', response.data)
            this.success = true
            this.dg()
          })
          .catch(error => {
            this.alertApiError(error)
          })
          .finally(() => {
            this.loading = false
          })*/
    },
    updateConta(conta) {
      this.$uloc.window.emit(this.$root.wid, 'reloadProdutividade')
      this.$uloc.window.close(this.$root.wid)
    }
  }
}
</script>
